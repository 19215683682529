body {
  margin: 0;
  font-family: -apple-system, 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #dcdfe1;
  color: rgb(0, 76, 144);
}

code {
  font-family: Monaco, Consolas, 'Roboto',
    monospace;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

h1{
  font-size: 2rem;
}
