.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header {
    margin-top: 50px;
    color: #004c90;
}

